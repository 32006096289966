import React from "react";
import { graphql } from "gatsby";
import ReactDOMServer from "react-dom/server";

import {
  HeroSimpleCentered,
  Layout,
  FAQCategory,
  LinkButton,
  SEO,
} from "../components";

const FAQsPage = ({ data }) => {
  const categories = data.allStrapiFaqCategories.edges;
  const faqs = data.allStrapiFaqs.edges;

  const schemaFaqs = faqs.map((faq) => {
    return {
      "@type": "Question",
      name: faq.node.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: ReactDOMServer.renderToStaticMarkup(faq.node.answer),
      },
    };
  });

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: schemaFaqs,
  };

  return (
    <Layout>
      <SEO
        title="Preguntas Frecuentes"
        description="Preguntas y respuestas acerca del servicio de energía solar sin inversión"
        schemaMarkup={schemaMarkup}
      />
      <HeroSimpleCentered
        title="Preguntas Frecuentes"
        description="Si eres nuevo en Bright esta sección te ayudará a obtener más información sobre nuestros servicios"
      />

      <div className="bg-white">
        <div className="mx-auto pb-12 px-8 max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          {categories.map((category) => {
            return <FAQCategory category={category.node} />;
          })}
        </div>
      </div>
      {/* Contact Us Start */}
      <div className="bg-gray-50 mt-8">
        <div className="max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-blue-500 sm:text-4xl">
            ¿Aún tienes preguntas sin respuesta?
          </h2>

          <div className="mx-auto mt-8 max-w-xs w-56">
            <LinkButton shadow arrow size="large" to="/contacto">
              Contáctanos
            </LinkButton>
          </div>
        </div>
      </div>
      {/* Contact Us End */}
    </Layout>
  );
};

export default FAQsPage;

export const query = graphql`
  query {
    allStrapiFaqCategories {
      edges {
        node {
          title
          description
          slug
          faqs {
            question
            answer
          }
        }
      }
    }
    allStrapiFaqs {
      edges {
        node {
          question
          answer
        }
      }
    }
  }
`;
